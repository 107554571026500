import './assets/main.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import i18n from './plugins/i18n'

import * as Sentry from '@sentry/vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://f3c991db96bab7ba5491ecf37ecb834a@o4507918603780096.ingest.us.sentry.io/4507918714535936',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['app.gurudian.com'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.VITE_ENVIRONMENT,
})

app.use(i18n)

app.use(createPinia())
app.use(router)

app.mount('#app')
