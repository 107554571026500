import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '~/pages/HomeView.vue'

const Layouts = {
  blank: 'BlankLayout',
  app: 'AppLayout',
}

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { layout: Layouts.blank },
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    meta: { layout: Layouts.app },
    component: () => import('~/pages/AboutView.vue'),
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   meta: { layout: Layouts.blank },
  //   component: () => import('~/pages/auth/LoginPage.vue'),
  // },
]

export default createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})
