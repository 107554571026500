<template>
  <component :is="layout || 'div'">
    <Toast />
    <RouterView />
  </component>
</template>

<script setup lang="ts">
const layout = shallowRef('')

router.afterEach(to => {
  const metaLayout = to.meta.layout as string
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  layout.value = (layouts as { [key: string]: any })[metaLayout]
})

provide('app:layout', layout)
</script>

<style scoped lang="scss"></style>
